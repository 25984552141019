<template>
  <div class="d-flex justify-content-center">
    <b-card class="content-card">
      <h2 class="mb-2">
        PDF Download Page
      </h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </p>

      <div class="p-2">
        <div class="d-flex align-items-center px-2 mb-1">
          <div>
            <img
              src="@/assets/images/icons/pdf-doc.png"
              width="50"
              height="50"
              alt="Download PDF"
            >
          </div>
          <a
            href="http://www.africau.edu/images/default/sample.pdf"
            class="px-2 mb-0 font-weight-normal"
          >
            This is the file or doc name
          </a>
        </div>
        <div class="d-flex align-items-center px-2 mb-1">
          <div>
            <img
              src="@/assets/images/icons/pdf-doc.png"
              width="50"
              height="50"
              alt="Download PDF"
            >
          </div>
          <a
            href="http://www.africau.edu/images/default/sample.pdf"
            class="px-2 mb-0 font-weight-normal"
          >
            This is the file or doc name
          </a>
        </div>
        <div class="d-flex align-items-center px-2 mb-1">
          <div>
            <img
              src="@/assets/images/icons/pdf-doc.png"
              width="50"
              height="50"
              alt="Download PDF"
            >
          </div>
          <a
            href="http://www.africau.edu/images/default/sample.pdf"
            class="px-2 mb-0 font-weight-normal"
          >
            This is the file or doc name
          </a>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style scoped>
.content-card {
  width: 75%
}

@media screen and (max-width: 1024px) {
  .content-card {
    width: 100%;
  }
}
</style>
